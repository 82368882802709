import React from 'react';
import appIconImage from './assets/app-icon.png';

function App() {
  return (
    <div className="h-screen flex items-center justify-center px-4 py-4 text-center bg-[#13559e]">
      <div className="bg-white rounded-lg p-4 md:p-12 flex flex-col items-center justify-center">
        <img src={appIconImage} className="mb-6 w-32 h-32" alt="The WOD Generator app icon" />

        <h1 className="text-5xl md:text-6xl font-semibold mb-6">The WOD Generator</h1>

        <p className="text-xl md:text-2xl mb-6 max-w-2xl mx-auto">
          10,000+ unique workouts in an easy to consume format with full definitions. Generate WODs
          online, or on-the-go with our iOS and Android apps.
        </p>

        <div className="flex-col flex space-y-3 mb-4 items-center justify-center">
          <a
            className="button"
            href="https://itunes.apple.com/us/app/wod-generator/id964854703?ls=1&mt=8"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download free for iOS
          </a>

          <a
            className="button"
            href="https://play.google.com/store/apps/details?id=com.henhub.wod"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download free for Android
          </a>

          <a
            className="button hollow"
            href="https://www.thewodgenerator.com/category"
            target="_blank"
            rel="noopener noreferrer"
          >
            Generate a WOD online
          </a>
        </div>

        <p className="max-w-xs mx-auto italic">
          Note: The mobile apps include many more WODs and features 😎
        </p>
      </div>
    </div>
  );
}

export default App;
